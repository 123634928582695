import React, { useEffect, useState } from "react";
import { HomepageBanner, HomepageCallout } from "gatsby-theme-carbon";
import HomepageTemplate from "gatsby-theme-carbon/src/templates/Homepage";
import { calloutLink } from "./Homepage.module.scss";
import Carbon from "./carbon.jpeg";
import config from "../aws-exports.js";

const FirstLeftText = () => <p>GCS & Implementation</p>;

const FirstRightText = () => (
  <p>
    Every release of Provision Portal includes release notes and any related
    guides that help keep everyone up to date on the changes made. Review the
    documentation in the <a href="getting-started">Getting Started</a> Guide.
  </p>
);

const SecondLeftText = () => <p>Developers and R&D</p>;

const SecondRightText = () => (
  <p>
    Whether you are a developer working on Provision Portal itself or an R&D
    engineer working on Automation Documents to be consumed by Provision portal,
    you can find more information in the Developer Guides and documentation
    <a className={calloutLink} href="/">
      Coming Soon →
    </a>
  </p>
);

const BannerText = () => <h1>Provision Portal Documentation</h1>;

const customProps = {
  Banner: <HomepageBanner renderText={BannerText} image={Carbon} />,
  FirstCallout: (
    <HomepageCallout
      backgroundColor="#030303"
      color="white"
      leftText={FirstLeftText}
      rightText={FirstRightText}
    />
  ),
  SecondCallout: (
    <HomepageCallout
      leftText={SecondLeftText}
      rightText={SecondRightText}
      color="white"
      backgroundColor="#061f80"
    />
  ),
};

// spreading the original props gives us props.children (mdx content)
function ShadowedHomepage(props) {
  const [login, setLogin] = useState(null);

  useEffect(() => {
    // Have to create a function with async inside of useEffect since it doesn't like async on the root function
    async function initAmplify() {
      const Amplify = await import("aws-amplify");
      Amplify.default.configure(config);
      try {
        // Authentication
        await Amplify.Auth.currentSession();
        const user = await Amplify.Auth.currentAuthenticatedUser();
        setLogin(user);
      } catch (error) {
        Amplify.Auth.federatedSignIn({ provider: "bd-login" });
      }
    }
    initAmplify();
  }, []);

  return (
    <div>{login ? <HomepageTemplate {...props} {...customProps} /> : null}</div>
  );
}

export default ShadowedHomepage;
