import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';

const Content = ({ buildTime }) => (
  <>
    <p>
      This site was last updated: {buildTime}
    </p>
    <p>
      Brought to you by the MMS DevOps Team.
    </p>
    <p>
      Key contributors to this project are:
        <ul>
           <li>Oleg Ashikhmin</li>
            <li>David Suarez</li>
            <li>Jesus Vargas</li>
        </ul>
    </p>
  </>
);

const links = {
  firstCol: [
    { href: 'https://provision-portal.dev.bddevops.com', linkText: 'Provision Portal - Dev' },
    { href: 'https://provision-portal.qa.bddevops.com', linkText: 'Provision Portal - QA' },
    { href: 'https://provision-portal.stage.bddevops.com', linkText: 'Provision Portal - Stage' },
    { href: 'https://provision-portal.bddevops.com', linkText: 'Provision Portal - Prod' },
  ],
  secondCol: [
    { href: 'https://github.com/bd-devops/provision-graphql-api', linkText: 'Provision GraphQL API' },
    { href: 'https://github.com/bd-devops/provision-api', linkText: 'Provision API' },
    { href: 'https://github.com/bd-devops/provision-portal', linkText: 'Provision UI' },
    { href: 'https://github.com/bd-devops/provision-infrastructure', linkText: 'Provision Infrastructure' },
      
  ],
};

const logo = () => (
    <img alt="BD Logo" src="https://devops-assets.prod.bdservices.io/logo-digital-white-png/PNG/bd_white_rgb_2_25.png"/>
)


const CustomFooter = () => <Footer links={links} Content={Content} Logo={logo}/>;

export default CustomFooter;
