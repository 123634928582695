import React from 'react';
import ThemeFeedbackDialog from 'gatsby-theme-carbon/src/components/FeedbackDialog/FeedbackDialog';
import axios from "axios";

const FeedbackDialog = ({ props }) => {
  const onSubmit = (data) => {
    axios.post('https://pp-feedback.bddevops.io/feedback', {...data})
        .then(response => {})
    console.log({ ...data });
  };

  return <ThemeFeedbackDialog {...props} onSubmit={onSubmit} />;
};

export default FeedbackDialog;
